import request from '@/utils/request'

//获取用户登陆token
// export function fetchUserLoginToken(params) {
//     return request({
//         url: '/inside/app/user/login',
//         method: 'post',
//         data: params
//     })
// }

//新获取用户登录token
// export function fetchUserLoginToken(params) {
//     return request({
//         url: '/inside/app/user/login/V1',
//         method: 'post',
//         data: params
//     })
// }
// //新获取用户登录token v2
// export function fetchUserLoginToken(params) {
//     return request({
//         url: '/inside/app/user/login/V2',
//         method: 'post',
//         data: params
//     })
// }
// 获取手机号
//新获取用户登录token v2
export function fetchUserLoginToken(params) {
    return request({
        url: '/api/login/synchronous',
        method: 'post',
        data: params
    })
}


//新获取短信验证码
export function fetchUserLoginSmsCode(phoneNumber) {
    return request({
        url: `/inside/app/sms/send/${phoneNumber}`,
        method: 'post'
    })
}

// 第三方接口调试
export function fetchUserLoginUser(id) {
    return request({
        url: `/system/user/${id}`,
        method: 'post'
    })
}
// 查询好友
export function getUser(phoneNumber){
    return request({
        url: `/api/user/getUser?phone=${phoneNumber}`,
        method: 'post'
    })
}
//
export function synchronous(params){
    return request({
        url: `/api/login/synchronous`,
        method: 'post',
        data: params
    })
}


